<template>
  <div  @mousedown="e => {
    e.preventDefault()
    if (this.disabled) return
    this.selectOpen = true
    }">
    <a-select
      :disabled="disabled"
      @mouseenter="mouseInInput = true"
      @mouseleave="mouseInInput = false"
      style="width: 100%"
      :open="selectOpen"
      @select="handleSelect"
      @change="change"
      :placeholder="placeholder"
      v-model="select"
    >
      <div @mouseenter="mouseInDropdownRender = true" @mouseleave="mouseInDropdownRender = false" slot="dropdownRender" slot-scope="menu">
        <div style="padding: 4px 8px; cursor: pointer;display: flex">
          <a-input @change="searchChange" @click="e=>e.target.focus()"/>
        </div>
        <a-divider style="margin: 4px 0;"/>
        <v-nodes :vnodes="menu"/>
      </div>
      <a-select-option v-for="item in items" :key="item" :value="item">
        {{ item }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
  export default {
    components: {
      VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes
      }
    },
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: {
      options: {
        type: Array,
        default: () => ([])
      },
      modelValue: {
        type: [String, Array, Object, Number]
      },
      placeholder: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        items: this.options,
        selectOpen: false,
        mouseInInput: false,
        mouseInDropdownRender: false,
        select: this.modelValue
      }
    },
    watch: {
      selectOpen: {
        handler(val) {
          if (val) {
            this.mouseClickObserver()
          } else {
            this.mouseClickObserverDestroy()
          }
        }
      },
      modelValue: {
        handler(val) {
          this.select = val
        }
      }
    },
    beforeDestroy() {
      document.removeEventListener('click', this.mouseClick)
    },
    methods: {
      handleSelect(value) {
        if (value) {
          this.selectOpen = false
        }
      },
      change(value) {
        this.$emit('change', value)
      },
      searchChange(e) {
        const _this = this
        setTimeout(() => {
          if (e.target._value) {
            _this.items = _this.items.filter((item) => `${item}`.toLowerCase().includes(`${e.target._value}`.toLowerCase()))
          } else {
            this.items = this.options
          }
        }, 50)
      },
      mouseClickObserver() {
        document.addEventListener('click', this.mouseClick)
      },
      mouseClickObserverDestroy() {
        document.removeEventListener('click', this.mouseClick)
      },
      mouseClick() {
        this.selectOpen = (this.mouseInInput || this.mouseInDropdownRender)
      }
    }
  }
</script>

<style scoped>

</style>
